$(document).ready(function () {
    //initialize swiper when document ready
    var mySwiper = new Swiper('#banner .swiper-container', {
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })

    var mySwiper = new Swiper('.parceiros-banner', {
        wrapperClass: 'parceiros-wrapper',
        slideClass: 'parceiro',
        slidesPerView: '4',
        spaceBetween: 30,
        grabCursor: true,
        breakpoints: {
            // when window width is <= 320px
            320: {
                slidesPerView: 2,
                spaceBetween: 5
            },
            // when window width is <= 480px
            480: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            // when window width is <= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        }
    })
});

if ($(window).width() > 767) {

    $('#pesquisa').on('focus', function () {
        $(".menu .navbar-nav").removeClass('fadeInLeft');
        $(".menu .navbar-nav").addClass('fadeOutLeft');
    });
    $('#pesquisa').on('blur', function () {
        $(".menu .navbar-nav").addClass('fadeInLeft');
        $(".menu .navbar-nav").removeClass('fadeOutLeft');
    });

}